import React, { useEffect } from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Alert } from "../../components";
import Title from "../../components/Title";
import { resetProfileApiResponses } from "../../store/auth/auth.slice";
import { ProfileSavedAccounts } from "../ProfileSavedAccounts";
import { LinksContainer, IconLink, secondaryText } from "./styles";

export const Profile = () => {
  // Named selectors
  const authState = (state) => state.auth;
  const savedAccountsState = (state) => state.savedAccounts;
  const institutionState = (state) => state.institution;

  // Pass in named selectors and gets state from redux
  const { user } = useSelector(authState);
  const { saveAccount } = useSelector(savedAccountsState);
  const { config } = useSelector(institutionState);

  // Hooks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetProfileApiResponses());
  }, [dispatch]);

  return (
    <>
      <Title
        title="Profile"
        subTitle={user?.first_name && user?.last_name ? `${user?.first_name} ${user?.last_name}` : ""}
      />

      {/* Alerts section */}
      {/* TODO: Alert saveAccount does not work as intended, this results in no alerts being displayed from the API call. */}
      <Alert message={saveAccount?.response?.message} severity={saveAccount?.response?.severity} />

      {/* Settings section */}
      <div style={{ boxSizing: "border-box", display: "flex", flexDirection: "column", gap: "1rem" }} >
        <Typography variant="h2" component="h2">User Settings</Typography>
        <LinksContainer>
          {
            !user?.from_sso
              ? <>
                <IconLink as={Link} arial-label="Click to go to change email" to="/profile/email">
                  <MailOutlineOutlinedIcon />
                  <Typography>Email</Typography>
                  <Typography sx={secondaryText}>{user.email_address}</Typography>
                  <EastOutlinedIcon />
                </IconLink>

                <IconLink as={Link} arial-label="Click to go to change password" to="/profile/password">
                  <VpnKeyOutlinedIcon />
                  <Typography>Password</Typography>
                  <EastOutlinedIcon sx={{ marginLeft: "auto" }} />
                </IconLink>
              </>
              : null
          }

          {
            config.services?.payments?.state_of_residence?.enabled
              ?
              (
                <IconLink as={Link} arial-label="Click to go to change your state of residence" to="/profile/state-of-residence">
                  <HomeOutlinedIcon />
                  <Typography>State of Residence</Typography>
                  {
                    user?.state_of_residence
                      ? <Typography sx={secondaryText}>{user?.state_of_residence}</Typography>
                      : null
                  }
                  <EastOutlinedIcon sx={!user?.state_of_residence ? { marginLeft: "auto" } : {}} />
                </IconLink>
              )
              :
              null
          }
        </LinksContainer>
      </div>

      {/* Accounts section */}
      <ProfileSavedAccounts />
    </>
  );
};
